import { ref } from "vue";
import { useAbort, useApi } from "@/use/api/useApi";
import { contentToSearchForm } from "~/utils/searchForm/searchFormWorker";
import { getDefaultRequestCommonInfo } from "@/utils/getters/defaultRequestInfo";
import { addYears, format } from "date-fns";
import { getFullMonthsRange } from "@/utils/formatter/dateFormatter";
import { getTemplateInfoExtended } from "@/utils/templates/templateFormatter";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";
import type { TabMode } from "@/stores/search/SearchFormInterface";

export function useSearchTemplates(tab: TabMode) {

  const request = ref<RequestCommonInfo>(getDefaultRequestCommonInfo());

  function cancelTemplateRequest() {
    if (request.value.request) request.value.request.cancel();
    request.value = getDefaultRequestCommonInfo();
  }

  function getDatePublicRange(f: string, t: string) {
    const now = new Date();
    const from = f ? new Date(f) : '';
    const to = t ? new Date(t) : '';

    let result = {};

    if (from && to) result = { dateFrom: from, dateTo: to };
    else if (!from && !to) result = { dateFrom: addYears(now, -1), dateTo: now };
    else if (from && !to) result = { dateFrom: from, dateTo: getFullMonthsRange(from, now) > 11 ? addYears(from, 1) : now }
    else if (!from && to) result = { dateFrom: addYears(to, -1), dateTo: to }

    return {
      dateFrom: format(result.dateFrom, 'yyyy-MM-dd 00:00:00'),
      dateTo: format(result.dateTo, 'yyyy-MM-dd 23:59:59'),
    }
  }

  /**
   * Получение фильтров выбранного шаблона по айдишнику
   */
  function fetchCurrentTemplate(id: number) {
    return new Promise((resolve, reject) => {
      if (!id) reject(null)
      else {
        if (request.value.request) request.value.request.cancel();

        const { signal, abort, } = useAbort();

        request.value = getDefaultRequestCommonInfo()
        request.value.loading = true;
        request.value.request = { cancel: abort };

        useApi().searchTemplates.fetchTemplateInfo(id, signal)
          .then((data) => {
            request.value.request = null;
            request.value.loading = false;
            resolve({ meta: getTemplateInfoExtended(data), content: contentToSearchForm(data?.content, tab) })
          })
          .catch((error) => {
            if (!signal.aborted) {
              request.value.request = null;
              request.value.loading = false;
              request.value.error = true;
              reject(error?.response?.status)
            }
          })
      }
    })
  }

  return {
    request,
    getDatePublicRange,
    cancelTemplateRequest,
    fetchCurrentTemplate,
  }
}
